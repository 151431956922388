export const homePageStrap = {
    title: "Mick's world of pike and predator fishing",
    blurb: [
        'Mick Brown is one of the most well known and certainly one of the most enthusiastic anglers in Great Britain. In particular he is renowned as one of the most successful freshwater predator anglers of his generation.',
    ],
};

export const homePageHero = {
    title: 'Lure success is in YOUR own hands',
    client: 'Mick Brown',
    subTitle: '',
};

export const doubleBlockTitle = 'The Duke...';
export const doubleBlockCopy = [
    // {
    //     copy: {
    //         title: 'Shop',
    //         client: '',
    //         blurb:
    //             "As Mick clears the remaining stock from his business, and any tackle he no longer requires, it will be offered for sale here. Have a look and see if there's anything which takes your fancy. All books and DVDs can be signed if you'd like.",
    //         readMore: 'Read more',
    //         linkUrl: '/shop',
    //     },
    //     imgUrl: '/img/shop/professional_pike_angler_book.jpg',
    //     imgPosition: 'right',
    // },
    {
        copy: {
            title: 'About Mick - The Duke',
            client: '',
            blurb: 'From TV shows to the author of great fishing reads...Mick Brown is one of the most well known and certainly one of the most enthusiastic anglers in Great Britain. In particular he is renowned as one of the most successful freshwater predator anglers of his generation.',
            readMore: 'Read more',
            linkUrl: '/about',
        },
        imgUrl: '/img/heros/about-mick2.webp',
        imgPosition: 'left',
    },
    {
        copy: {
            title: 'Predator Talk',
            client: '',
            blurb: 'Here we have a collection of short features written by Mick to help his fellow predator anglers get the best from their sport. Since Mick retired, some product detail and references may be out-of-date, but viewers should find them instructional and entertaining just the same.',
            readMore: 'Read more',
            linkUrl: '/predator-talk',
        },
        imgUrl: '/img/heros/tips2.webp',
        imgPosition: 'right',
    },
    {
        copy: {
            title: 'Great Pike Fishing Days',
            client: '',
            blurb: 'Mick has recently written a new book - Great Pike Fishing Days. The original print run sold out in a few weeks, but more have been printed for those who missed a copy. Follow this link to get one of the few remaining copies. Sorry, this book is SOLD OUT!',
            readMore: 'Read more',
            linkUrl:
                'https://www.matthayes.tv/http:/www.matthayes.tv/mh-shop/greatpike/',
        },
        imgUrl: '/img/heros/mick-matt.png',
        imgPosition: 'left',
    },
];
