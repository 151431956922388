const HeaderCopy = {
    headerLinks: [
        {
            linkTo: '/',
            label: 'Home',
            active: true,
            sideNav: true,
            headerNav: true,
        },
        {
            linkTo: '/shop',
            label: 'Shop',
            active: false,
            sideNav: true,
            headerNav: true,
        },
        {
            linkTo: '/predators',
            label: 'Predators',
            active: true,
            sideNav: true,
            headerNav: true,
        },
        {
            linkTo: '/predator-talk',
            label: 'Predator Talk',
            active: true,
            sideNav: true,
            headerNav: true,
        },
        {
            linkTo: '/predators/pike',
            label: 'Pike',
            active: true,
            sideNav: false,
            headerNav: false,
        },
        {
            linkTo: '/predators/eel',
            label: 'Eel',
            active: true,
            sideNav: false,
            headerNav: false,
        },
        {
            linkTo: '/predators/zander',
            label: 'Zander',
            active: true,
            sideNav: false,
            headerNav: false,
        },
        {
            linkTo: '/predators/catfish',
            label: 'Catfish',
            active: true,
            sideNav: false,
            headerNav: false,
        },
        {
            linkTo: '/gallery',
            label: 'Gallery',
            active: true,
            sideNav: true,
            headerNav: true,
        },
        {
            linkTo: '/about',
            label: 'About',
            active: true,
            sideNav: true,
            headerNav: true,
        },
        {
            linkTo: '/contact',
            label: 'Contact',
            active: true,
            sideNav: true,
            headerNav: true,
        },
    ],
};

export default HeaderCopy;
