import React from 'react';

const AboutContent = ({ content }) => {
    const renderedContent = content.blurb.map((i, idx) => (
        <p key={idx}>
            {i.imageUrl && (
                <img src={i.imageUrl} className={i.imageClass} alt="Mick" />
            )}
            {i.para}
        </p>
    ));
    return <div className="about-content">{renderedContent}</div>;
};

export default AboutContent;
