import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const TabContent = ({ content }) =>
    content.map((item, idx) => {
        const { title, blurb } = item;
        return (
            <React.Fragment key={idx}>
                {title && <h4>{title}</h4>}
                {blurb.map((item, idx) => {
                    return (
                        <React.Fragment key={idx}>
                            <p>
                                {item.imageUrl && (
                                    <img
                                        src={item.imageUrl}
                                        className={item.imageClass}
                                        alt="Mick"
                                    />
                                )}
                                {item.para}
                            </p>
                        </React.Fragment>
                    );
                })}
                {}
            </React.Fragment>
        );
    });

const AboutTabs = ({ content }) => {
    const { television, sponsorship, publishing } = content;
    return (
        <Tabs>
            <TabList>
                <Tab>Television</Tab>
                <Tab>Sponsorship</Tab>
                <Tab>Publishing</Tab>
            </TabList>

            <TabPanel>
                <TabContent content={television} />
            </TabPanel>
            <TabPanel>
                <TabContent content={sponsorship} />
            </TabPanel>
            <TabPanel>
                <TabContent content={publishing} />
            </TabPanel>
        </Tabs>
    );
};

export default AboutTabs;
