import React from 'react';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import predatorTalkPosts from 'constants/containers/predatorTalkPosts';

const breakpointColumnsObj = {
    default: 3,
    1024: 3,
    768: 2,
    576: 1,
};

const posts = predatorTalkPosts.map((post, idx) => {
    const { url, articleCopy, pageStrap } = post;
    const { images, paras } = articleCopy.blurb;
    return (
        <Link className="predator-container" to={url} key={idx}>
            <div className="predator-container__image-holder">
                <img src={images[0].src} key={images[0].src} alt="Gallery" />
            </div>
            <div className="predator-container__copy-holder">
                <h3>{pageStrap.title}</h3>
                <p className="copy-para">{`${paras[0].slice(0, 140)}...`}</p>
                <div className="button-holder">
                    <div className="read-more-predators">{`Read more`}</div>
                </div>
            </div>
        </Link>
    );
});

const PredatorTalkPosts = () => {
    return (
        <>
            <div className="predator-talk inner-content">
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    {posts}
                </Masonry>
            </div>
        </>
    );
};

export default PredatorTalkPosts;
