import React from 'react';
import { Link } from 'react-router-dom';

const ReadMoreLink = ({ url, mode = 'light' }) => {
    return (
        <Link className={`read-more-link ${mode}`} to={url}>
            Read more
        </Link>
    );
};

export default ReadMoreLink;
