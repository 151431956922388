import React from 'react';

const ShopItems = ({ items }) => {
    const renderedContent = items.map((i, idx) => (
        <div key={idx} className="item__container">
            <div className="item__container--title">{i.title}</div>
            <div className="item__container--content">
                <div className="item-content">
                    <div className="content-title">Price:</div>
                    <div className="content-copy">{i.price}</div>
                    <div className="content-title">Description:</div>
                    <div className="content-copy">{i.description}</div>
                </div>
                <div className="item-image">
                    <img src={i.imagePath} alt={i.title} />
                </div>
            </div>
        </div>
    ));
    return <div className="inner-content">{renderedContent}</div>;
};

export default ShopItems;
