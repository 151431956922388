import React, { useState } from 'react';
import Masonry from 'react-masonry-css';
import galleryImages from 'constants/galleryImages';
import { SiteModal, GalleryModalImage } from 'components';

const breakpointColumnsObj = {
    default: 3,
    1024: 3,
    768: 2,
    576: 1,
};

const GalleryImages = () => {
    const [showGalleryModal, setShowGalleryModal] = useState({
        show: false,
        imgPath: '',
    });

    const handleCloseGalleryModal = () =>
        setShowGalleryModal({
            show: false,
        });
    const handleShowGalleryModal = stateObject => {
        setShowGalleryModal({
            show: true,
            imgPath: stateObject.imgPath,
        });
    };

    const gallery = handleShowGalleryModal => {
        return galleryImages.reverse().map(image => (
            <button key={image.path} title="Click to zoom">
                <img
                    src={image.path}
                    alt="Gallery"
                    onClick={e => {
                        e.preventDefault();
                        handleShowGalleryModal({
                            show: true,
                            imgPath: image.path,
                        });
                    }}
                />
            </button>
        ));
    };

    return (
        <div className="gallery-images">
            <SiteModal
                showModal={showGalleryModal.show}
                closeModal={handleCloseGalleryModal}
                customClass="gallery-image"
            >
                <GalleryModalImage imgPath={showGalleryModal.imgPath} />
            </SiteModal>
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {gallery(handleShowGalleryModal)}
            </Masonry>
        </div>
    );
};

export default GalleryImages;
