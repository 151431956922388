import React from 'react';
import { DoubleBlock } from 'modules';
import { doubleBlockCopy } from 'constants/containers/home';

const DoubleBlockContainer = () => {
    return (
        <div className="w-100 border-bottom story-block">
            <div className="inner-content">
                <div className="story-block-container">
                    {doubleBlockCopy.map((item, idx) => (
                        <DoubleBlock {...item} key={idx} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DoubleBlockContainer;
