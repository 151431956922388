import React from 'react';
import { Header, Footer } from 'modules';
import { SideBarMenu } from 'components';
import './Layout.scss';

const Layout = ({ children }) => {
    return (
        /* This ID is required for the side menu */
        <div id="outerContainer">
            <SideBarMenu
                pageWrapId={'pageWrap'}
                outerContainerId={'outerContainer'}
            />
            <main className="main-content-container" id="pageWrap">
                <Header />
                {children}
                <Footer />
            </main>
        </div>
    );
};

export default Layout;
