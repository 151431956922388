import React from 'react';
import { Link } from 'react-router-dom';
import HeaderCopy from 'constants/modules/Header.js';
import { GetCurrentPage } from 'utils/commonUtils';

const { headerLinks } = HeaderCopy;

const Links = () => {
    const currentPage = GetCurrentPage();
    return headerLinks
        .filter(link => link.active)
        .filter(link => link.headerNav)
        .map(link => {
            const isActive = currentPage === link.linkTo;
            return (
                <li key={link.linkTo}>
                    <Link
                        to={link.linkTo}
                        className="my-auto"
                        aria-current={isActive}
                    >
                        {link.label}
                    </Link>
                </li>
            );
        });
};

const Header = () => {
    return (
        <header data-module="header" className="">
            <div className="header__wrapper">
                <div className="inner-content">
                    <Link to="/">
                        <img
                            src="/img/brand/logo_white.png"
                            className="logo"
                            alt="Company logo"
                        />
                    </Link>
                    <ul className="header-nav">
                        <Links />
                    </ul>
                </div>
            </div>
        </header>
    );
};

export default Header;
