const predatorsPosts = [
    {
        id: 'mackerelTail',
        url: '/predator-talk/the-good-old-mackerel-tail',
        pageStrap: {
            title: 'The good old mackerel tail',
            blurb: [],
        },
        author: 'Mick Brown',
        articleCopy: {
            blurb: {
                images: [
                    {
                        para: null,
                        src: '/img/page-images/predator-talk/mack1.jpg',
                        imgClass: 'main',
                    },
                    {
                        para: 1,
                        src: '/img/page-images/predator-talk/mack2.jpg',
                        imgClass: 'right',
                    },
                    {
                        para: 3,
                        src: '/img/page-images/predator-talk/mack3.jpg',
                        imgClass: 'left',
                    },
                ],
                paras: [
                    'My winter pike fishing usually kicks off in mid October, and deadbaiting will be very effective on my local pits, rivers and drains from then onwards. A favourite deadbait is a mackerel tail which is aerodynamic for casting and absolutely oozing with attractive oils and juices which draw pike from far and wide. It’s a tactic I’ve used for over forty years and it is still as effective today as it ever was. My first 20 pounder on a mackerel tail came in 1975 from The Old Bedford River in the Fens and since then I have caught more than 50 twenty pounders with mackerel bait and also a 31 pounder.',
                    'It’s easy to use on a simple float rig or leger rig, and easy to pop up too.',
                    'More and more I am using mackerel tails for zander too, and have found them very effective on some waters, but not all. Certainly on the Fen rivers and some local stillwaters they work very well and have produced several double figure fish for me.',
                    'You would be very unwise to ignore the mackerel tail as a pike and zander bait.',
                    'TIP: As the water gets colder, use a smaller piece of bait. You may be surprised how much difference it can make.',
                    'All of my rigs for using mackerel and other deadbaits can be found in the Fox publication – Mick Browns Guide to Pike and Predators. Obtainable from larger tackle shops or Ebay.',
                ],
            },
        },
    },
    {
        id: 'scentTrails',
        url: '/predator-talk/scent-trails',
        pageStrap: {
            title: 'Scent trails',
            blurb: [],
        },
        author: 'Mick Brown',
        articleCopy: {
            blurb: {
                images: [
                    {
                        para: null,
                        src: '/img/page-images/predator-talk/scent1.jpg',
                        imgClass: 'main',
                    },
                    {
                        para: 2,
                        src: '/img/page-images/predator-talk/scent2.jpg',
                        imgClass: 'left',
                    },
                    {
                        para: 4,
                        src: '/img/page-images/predator-talk/scent3.jpg',
                        imgClass: 'right',
                    },
                ],
                paras: [
                    'First glance at the first picture in this article might just look like I’m standing in the water and stirring up the mud. Well that’s what I’m doing, but it can tell you something very important if you are fishing with deadbaits for pike or other predators. The fact is that that undercurrents, formed by wind and temperature variations, on any stillwater, will cause this effect, and it serves to spread the scent emanating from your deadbait around the surrounding water. The mud slick formed by me standing in the margin has spread several metres in just a minute or so. Imagine how far the scent trail from your deadbait could travel in a slightly longer period.',
                    'With this in mind I always consider how the prevailing conditions are affecting my chances of success. Windy days tend to be better for deadbaiting as they set up stronger undercurrents. Venues with large variations in depth will warm and cool at different rates in different areas, and this sets up water movement as the temperature tries to equalise. There are other forces at work too, which are too complex to understand, but all we need to know is that this phenomena exists.',
                    'How does this affect my approach to deadbaiting for pike then? Well, direction of current is important. It may be going in a direction where there are no pike. That might be why you are not catching! It’s quite difficult to judge direction. For example, at the down wind end of a large body of water, the undercurrent is usually travelling away from the bank and into the wind after the force of water hits the bank and is pushed away by the relentless pressure. Such a situation could see the current moving parallel to the bank if it hits it at a slight angle. Then we have to consider very still days, when minimal undercurrents are forming. How often on such days do the pike never get to smell your bait.',
                    'On days when pike are searching for food, you naturally have a better chance of them finding your deadbait.',
                    'They are not always hunting though, and feeding spells can be very infrequent, especially in colder conditions when their feeding spells are short sharp affairs. Pike that are well fed, or those digesting a recent meal, are also unlikely to be searching for food, so a good scent trail can be very beneficial at these times.',
                    'So, the point of this article is that I would advise that one should always be aware of scent trails when deadbaiting. I always consider the direction of the trail and it’s strength. You can often judge flow direction by making a cast with the bait set to mid water and see which way it travels. This would lead me to move my bait into different areas if I’m not catching, and to consider tactics that draw pike through a scent trail like groundbaiting and putting additives into my bait that offer a stronger scent than the baits natural properties. In these situations I’ll normally puncture baits or use half baits or sections. I’ll try to use really fresh baits dripping with body fluids like the half herring in the second picture. I’ll get my baits well popped up if the scent is being lost in a muddy or weedy bottom. There is so much more you can do that space forbids me to include which I’ll have to save for another time. Putting more thought into making use of scent trails and experimenting with my tactics has made my pike deadbait fishing far more interesting than a  ‘sit and wait’  approach, and definitely more productive.',
                ],
            },
        },
    },
    {
        id: 'braidOrMono',
        url: '/predator-talk/braid-or-mono-for-pike',
        pageStrap: {
            title: 'Braid or mono for pike?',
            blurb: [
                'The question of whether to use braid or mono line for lure fishing was resolved for me a long time ago.',
            ],
        },
        author: 'Mick Brown',
        articleCopy: {
            blurb: {
                images: [
                    {
                        para: null,
                        src: '/img/page-images/predator-talk/braid1.jpg',
                        imgClass: 'main',
                    },
                    {
                        para: 2,
                        src: '/img/page-images/predator-talk/braid2.jpg',
                        imgClass: 'left',
                    },
                ],
                paras: [
                    'Without doubt, braid has proved itself to be the far better choice in most pike fishing situations.  Minimal stretch means better bite detection, and it’s low diameter relative to breaking strain makes it very user friendly. For me, one of the other important benefits I get from braid line is it’s reliability. Knots are easy to tie and very reliable, and the line itself takes a lot of abuse. Gone are the days when I’m pulling for breaks in snags and losing my valuable lures. For lure fishing, I will never go back.',
                    'So, what about bait fishing ? It took me a bit longer to completely change over to braid, but nowadays I would use nothing else unless forced to do so by fishery rules. For the same reasons as I choose braid for lure fishing, I do so for bait fishing. My biggest problem when bait fishing was that the lack of stretch made the playing process more fraught with the stiffer bait rods. I was afraid of hook pulls, and indeed did suffer a few. Before long though, I simply found that playing pike on braid line required a slightly modified technique that applied less pressure and keeping the rod higher to absorb any sudden lunges.',
                    'More benefits were found like the reliability in striking at long range, particularly when drifter float fishing or long trotting baits down a river or flowing drain. And I keep coming back to reliability. When I have a bait caught in a snag or have to haul a pike from thick weed or from tree roots, I have every confidence that the line will not let me down. Power Pro has long been my choice of braid, and for bait fishing I use 15 kg breaking strain. In swims with serious snags you can go much stronger with little increase in diameter, but I would question whether it’s wise to fish such swims where snagging is so likely.',
                    'Braid is more expensive than mono but it will last so much longer than mono main line. With average use, I would expect to get at least five years use from my braid. If you are a penny pincher, you can reverse the braid after five years and get a few more from it. In the long run, it’s a choice I don’t think you will ever regret.',
                ],
            },
        },
    },
    {
        id: 'allTogether',
        url: '/predator-talk/all-together-girls',
        pageStrap: {
            title: 'All together girls',
            blurb: [],
        },
        author: 'Mick Brown',
        articleCopy: {
            blurb: {
                images: [
                    {
                        para: null,
                        src: '/img/page-images/predator-talk/alltogether1.jpg',
                        imgClass: 'main',
                    },
                    {
                        para: 0,
                        src: '/img/page-images/predator-talk/alltogether2.jpg',
                        imgClass: 'right',
                    },
                ],
                paras: [
                    'Here’s a quick tip that could save you a lot of disappointment, help you catch more pike and avoid a few blanks. In a nutshell, if you can find one big female pike in the winter months, you may be onto an area that has many more. Whether fishing rivers, drains or lakes, I will always keep on the move unless I know I am onto some fish. The fact is that they will nearly always be grouped quite tightly in the winter. Sometimes it’s because they are close to prey fish, but at other times, they simply take up residence in a particular spot for reasons only known to them. Often they will take up these spots every season.',
                    'On some waters though, they locate themselves differently each winter. The more features there are on the venue, the more choices they have. And remember that there are features under the water that you can’t see. I can recall so may instances of moving several times after blanking and then dropping into a swim full of pike where lots of runs come in quick succession. If you regularly sit blanking, think about getting mobile. Cut down on the tackle so you can do this. When you do finally catch a big female pike, get another bait in the same area quickly – there will probably be more!',
                ],
            },
        },
    },
    {
        id: 'waterTemp',
        url: '/predator-talk/water-temperature-warning',
        pageStrap: {
            title: 'Water temperature warning',
            blurb: [],
        },
        author: 'Mick Brown',
        articleCopy: {
            blurb: {
                images: [
                    {
                        para: null,
                        src: '/img/page-images/predator-talk/watertemp1.jpg',
                        imgClass: 'main',
                    },
                    {
                        para: 1,
                        src: '/img/page-images/predator-talk/watertemp2.jpg',
                        imgClass: 'left',
                    },
                    {
                        para: 3,
                        src: '/img/page-images/predator-talk/watertemp3.jpg',
                        imgClass: 'right',
                    },
                ],
                paras: [
                    'It always worries me when anglers start fishing for pike on the Traditional October 1st starting date. The problem I foresee is with regard to the water temperature that we can expect at this time of year. This is particularly worrying after a very warm back end to the summer. On many waters in the UK, especially in lowland southern areas, I would expect the water temperature to be dangerously high at this time for reasons I will go on to explain. It’s hard to put precise figures to this, but I feel that a water temperature higher than 12/13 degrees Centigrade can raise issues that are detrimental to pike welfare if they are captured at this time. Pike tend to fight like crazy at such elevated temperatures, and cannot get enough oxygen through their gills to aid a safe recovery. This will be the case whatever method you are using, lure or bait, but I think bait fishing raises an even greater additional potential problem. At such water temperatures you can expect pike to bolt baits down extremely quickly, and no matter how good your bite indication and how quickly you react, you may not be able to strike in good time to prevent it.',
                    'I once spent an afternoon on a local stillwater. I had two runs on average sized baits, and both pike swallowed them out of sight, leaving both trebles in the stomach / throat. Only decades of experience, and the use of semi - barbed trebles, helped get me out of a nasty situation. I checked the water temperature, and wasn’t surprised that this had happened when I found it was 16.5 degrees C. Had I not checked with a very accurate aquarium thermometer, I would have sworn it felt cold enough for safe fishing.',
                    'I went again the next day just to see whether I could use tactics that eliminated this problem. After all, many other pike anglers will be out now and I felt the need to experiment and pass on my experience. I first checked the water temperature, and it had fallen half a degree overnight. Still too warm I think. I decided to rig with caution and opted for one size 4 semi barbed treble in the root of the tail instead of two size 6’s in tail and flank as the day before.',
                    'Once again, I had just two runs, and striking the first as quickly as I could, the bait was still swallowed almost out of sight. With the solitary treble lodged just outside the throat, it was a simple unhooking procedure, even though not satisfactory as I aim to hook in the scissors or near the front of the jaw. Nevertheless, a great improvement. Another run an hour later saw a similar situation, but with the treble pulling out of the tail of the bait during the fight and lodging in the scissors, while the bait was still in the throat entrance. I’d got away with it this time, but only just.',
                    '                    So, even after taking all precautions of good bite indication, with no delay and an immediate strike, I know I can still be faced with a tricky and dangerous(for the pike) unhooking problem when fishing at such elevated water temperature. I’m not desperate to catch pike, so I will now leave the deadbaiting alone until the water temperature drops a few more degrees. I can still fish with lures, although larger lures are best just now as smaller ones can be taken deeper at this time. I also need to take care when playing them and ensure they don’t exhaust themselves by prolonging the fight. I hope this practical experience will make you think about your own approach, not just now but at any time, and ensure you are tackle up safely and have all the tools ready to deal with any tricky situations.',
                ],
            },
        },
    },
    {
        id: 'howManyRods',
        url: '/predator-talk/how-many-rods',
        pageStrap: {
            title: 'How many rods',
            blurb: [],
        },
        author: 'Mick Brown',
        articleCopy: {
            blurb: {
                images: [
                    {
                        para: null,
                        src: '/img/page-images/predator-talk/howmanyrods1.jpg',
                        imgClass: 'main',
                    },
                    {
                        para: 2,
                        src: '/img/page-images/predator-talk/howmanyrods2.jpg',
                        imgClass: 'right',
                    },
                ],
                paras: [
                    'The other day I was deadbaiting on a large stillwater where you can count the number of runs you get in a season on one hand.  Some seasons you don’t even get a single run!  The pike are there though, and well worth struggling for.  An angler passing by after fishing a more prolific adjacent lake, expressed his amazement that I was using three rods.  He was even more staggered when I replied that I often use four rods there, one of them usually for lure fishing.  I was well within the law, having four rods covered by two EA licenses, and the rods were never spaced more than three meters apart.',
                    'I went on to explain to him why I sometimes used that many rods, and I would like to emphasise the words ‘at times’, as I don’t always use that many. My philosophy is to use the least number of rods I can without compromising my results. My ultimate scenario is to use just one rod. When exclusively lure fishing, this is obviously the case, but when bait fishing, I have to decide the number of rods that is sensible. Once again, I would like to use just one bait rod because that normally means I am getting lots of action and need no more. This is rarely the case though, and I mostly use two rods, giving me options of trying different baits and rigs. Sometimes the nature or size  of the venue dictates that two rods are the practical limit.',
                    'Now we are back to the scenario I opened with. When I find myself on a water where runs are hard to come by, I will step up to three rods, and where runs are almost unheard of I don’t mind putting out a fourth. In these latter situations, it is most unlikely that I’ll get multiple runs, although I concede it can happen and I’m ready for it. Whether it makes any difference to my results I don’t know. Maybe its a confidence thing, but I feel better by increasing the painfully low odds.',
                    'Once the sessions starts, I do have the option of reducing or increasing the number of rods until I am satisfied that I have arrived at a practical number. Where it goes wrong, in my opinion, is when anglers put out too many rods on prolific waters, simply to catch as many pike as they can. Multiple runs can occur, and runs on other rods come when unhooking a fish on the mat and you have to deal with two(or more) at the same time. We are fortunate at the moment to have laws that permit us to use up to four rods. If we abuse it, it will be to the detriment of the pike and make a case for changing the law. All it needs is a bit of common sense!',
                    '*** Correct at time of writing. Rod laws have since been updated so please check.',
                ],
            },
        },
    },
    {
        id: 'twitchIt',
        url: '/predator-talk/twitch-it',
        pageStrap: {
            title: 'Twitch it!',
            blurb: [],
        },
        author: 'Mick Brown',
        articleCopy: {
            blurb: {
                images: [
                    {
                        para: null,
                        src: '/img/page-images/predator-talk/twitchit1.jpg',
                        imgClass: 'main',
                    },
                    {
                        para: 0,
                        src: '/img/page-images/predator-talk/twitchit2.jpg',
                        imgClass: 'left',
                    },
                ],
                paras: [
                    'I learnt a little trick many years ago that has caught me so many pike that I’ve lost count. I’ve written about it many times and yet, going by observations made on my travels, it does not seem widely practised at all. It simply involves twitching your deadbait to give it a little movement. Sometimes pike need to see movement in a potential meal to tempt them to attack it. Lets face it, a deadbait that moves does imitate a dying prey fish taking it’s last gasps of life and is therefore a prime meal that might get away if the pike doesn’t nail it. The best way of fishing a deadbait to make twitching easy is with a float rig. Leger rigs can be twitched, but you can never be sure whether you have pulled the bait into weed or snags. With a float set up, you can feel the bait pick up from the bottom and glide through the water. The take is more obvious with a float rig too as you do not have the resistance of a heavy weight pulling back as with a leger rig. This ploy works just as well with a popped up deadbait. In fact, I think that the bait looks even more enticing when it’s popped up and made to bounce along just off bottom.',
                    'Nowadays, it’s my normal practice to twitch the float fished deadbaits by a couple of metres or so every ten minutes, re - casting them when I’m back to the rod. Actually, many of the takes come on the last part of the retrieve, and occasionally as I’m lifting the bait from the water. When you haven’t got the option to use livebaits, this is a very good next choice. Don’t take my word for it – try it and see for yourself!',
                ],
            },
        },
    },
    {
        id: 'planningAhead',
        url: '/predator-talk/planning-ahead-big-pike',
        pageStrap: {
            title: 'Planning ahead for big pike',
            blurb: [],
        },
        author: 'Mick Brown',
        articleCopy: {
            blurb: {
                images: [
                    {
                        para: null,
                        src:
                            '/img/page-images/predator-talk/planningahead1.jpg',
                        imgClass: 'main',
                    },
                    {
                        para: 2,
                        src:
                            '/img/page-images/predator-talk/planningahead2.jpg',
                        imgClass: 'left',
                    },
                ],
                paras: [
                    'Every winter I dream of catching a monster pike. My personal best of 37lb 8oz will take some beating, but we all need something to dream about. When that float slides away and something unknown has grabbed your deadbait, your mind wanders to what it might be this time. Whether its a jack, a double, a twenty or bigger doesn’t matter to me, it’s the excitement of what it might be. I appreciate them all, handle them carefully, enjoy the moment, and then try to make it happen again.',
                    'Pike fishing for me is just like that, but if you want to catch a big pike, here’s a tip that many pike anglers don’t stop to realise. Big pike mostly come from special waters, and they usually come from waters that have an over abundance of prey fish. Furthermore, those waters need to be neglected by other pike anglers as big pike do not take well to being caught repeatedly.',
                    'My planning starts well ahead of the traditional October start. Through the summer I’m getting my gear ready, but above all I’m researching the venues I’m going to tackle. I never believe what I hear from others or read in the press, I go and take a look myself and ask questions.I ’ll often fish for the prey fish to get a feel for the venue. By October, I’ll probably have three waters to concentrate my efforts on rather than put all my eggs into one basket. Sometimes I get it wrong and the venue does not have the potential I suspected. Sometimes the fishing may be fraught with problems like unexpected dense weed. Now and again I find that the water has been poached or maybe hammered by other pike anglers. I need a few alternatives in case things start to go wrong. By mid winter, I’ve usually settled on the water that I think will produce the goods. I don’t expect a quick result, but will work at it until it comes.',
                    'Most of the big pike you can read about in my books have come through this planning strategy, and if you take time to plan ahead, I am sure you will have a better result yourself. If it doesn’t come this season, you have something to build on for the next. I was going to wish you ‘good luck’ but, as you might now realise, luck has very little to do with success with big pike!!',
                ],
            },
        },
    },
    {
        id: 'flyRetrieves',
        url: '/predator-talk/pike-fly-fishing-retrieves',
        pageStrap: {
            title: 'Pike fly fishing retrieves',
            blurb: [],
        },
        author: 'Mick Brown',
        articleCopy: {
            blurb: {
                images: [
                    {
                        para: null,
                        src: '/img/page-images/predator-talk/flyretrieves1.jpg',
                        imgClass: 'main',
                    },
                    {
                        para: 0,
                        src: '/img/page-images/predator-talk/flyretrieves2.jpg',
                        imgClass: 'left',
                    },
                    {
                        para: 1,
                        src: '/img/page-images/predator-talk/flyretrieves3.jpg',
                        imgClass: 'right',
                    },
                ],
                paras: [
                    'When fly fishing for pike, I’ve noticed that the way the fly is retrieved can make a big difference to what you catch – or don’t catch! I’ve seen this in practice many times when fishing with other anglers. One often catches more than the other and, although there are other factors, retrieve style is often the only real difference in the approach. For this reason, I will vary my retrieve style until I find something that triggers them. As with fly fishing for any species, there are a few standard retrieve styles. Most pike fly anglers will ‘strip’ the fly by pulling back a certain length of line in a regular pattern, This can be varied by the length of line retrieved in each pull, the speed at which it is pulled back, and pauses of various times between each strip. Then you can use a ‘figure of eight’ style which is a gathering of the line between the thumb and fingers so as to give a constant line speed. Once again it pays to vary the line retrieve speed and make use of pauses during the retrieve. Then there is the ‘roly poly’ retrieve which is performed by holding the rod between ones legs while stripping at high speed with both hands.',
                    'There is a lot of variation in these retrieves, and I often mix them up to give a lot of variety to any fish that might be interested. One of my favourite retrieves which I never see others use, although I’m sure there are some that do so, is to make use of my rod tip to accelerate the fly so it really darts at high speed. It simply involves getting into a rhythm of an accelerating strip, followed by a line gathering strip. When making the accelerating strip, I pull line back with my retrieving hand whilst moving the rod tip vigorously to one side. This is quickly followed by a shorter strip that takes up the inevitable slack line. It’s a bit like using the hauling technique when casting to increase line speed except that you are doing so with the fly underwater. Again, you can vary this speed and the distance that the fly darts, and don’t forget those pauses.',
                    'Catching pike on the fly is usually not that difficult when they are active. When they are not, the challenge is to find out what is required to make them react, and this, to me, is the most rewarding fly fishing of all.',
                ],
            },
        },
    },
    {
        id: 'movingHotSpots',
        url: '/predator-talk/moving-hot-spots',
        pageStrap: {
            title: 'Moving hot spots',
            blurb: [],
        },
        author: 'Mick Brown',
        articleCopy: {
            blurb: {
                images: [
                    {
                        para: null,
                        src:
                            '/img/page-images/predator-talk/movinghotspots1.jpg',
                        imgClass: 'main',
                    },
                    {
                        para: 2,
                        src:
                            '/img/page-images/predator-talk/movinghotspots2.jpg',
                        imgClass: 'right',
                    },
                ],
                paras: [
                    'For decades now, experienced pike anglers have recognised the phenomena of ‘Hot Spots’. These are places where larger pike are always present whilst surrounding areas are almost devoid of their kind. They are sometimes associated with a particular feature, such as an area where there is decaying weed or where a river or drain narrows down, or they could be places where prey fish are always present. The precise reason for a ‘Hot Spot’ may never be known, but all I need to know is that they can exist, and they are what I am always seeking out, particularly in winter time.',
                    'There’s nothing new in this revelation, but in recent seasons I’ve noticed a slight variation on the theme – ‘moving’ hot spots. On some waters I fish, the ‘Hot Spot’ tends to change every season. One winter the big pike will be gathered on a drop off that is easy to cast to from the bank, another season they will be up against a drop off well out into the lake where I need a drifter float to reach them, and this year I found them in a relatively shallow area adjacent to very dense underwater weed.',
                    'The point I’m trying to make is that I could easily have missed out on some good sport had I not searched for the new ‘Hot Spot’. I could have sat in the swims that produced last winter, or the winter before, and blanked, blaming my lack of success on the weather or my bait and rigs. The fact is that all I was doing wrong was fishing in the wrong place. As soon as I found the new ‘Hot’ area, it was runs galore, using simple rigs and cheap bait. There was nothing clever about it, I just tried different areas until I stumbled across it. Since my first discovery of the swim, I have tried all the old ‘Hot Spots’ and had just one run. The pike were all holed up in this tight area which they have chosen to be in for the winter.',
                    'I treat these ‘Hot’ areas as I would pre baited swims. I just catch one or two fish each session and then leave them alone for a week. If you overdo it, they will simply move away and you have to find them again. As there are other lakes in the vicinity, I spend the rest of my days exploring them – looking for, and hoping to find new ‘Hot’ areas. When you have several ‘Hot’ swims to fish through a day, you’ve really got your act together!!',
                ],
            },
        },
    },
    {
        id: 'shortEelSessions',
        url: '/predator-talk/short-eel-sessions',
        pageStrap: {
            title: 'Short Eel Sessions',
            blurb: [],
        },
        author: 'Mick Brown',
        articleCopy: {
            blurb: {
                images: [
                    {
                        para: null,
                        src: '/img/page-images/predator-talk/eel3.jpg',
                        imgClass: 'main',
                    },
                ],
                paras: [
                    'I just love eel fishing. Everything about it appeals to me. I like the warm feeling in the summer nights, and the simplicity of the methods I use. Bait choice is easy, and it’s either a bunch of worms or a chunk of fish that goes on to my size four hook. The rig is invariably a running leger set up utilising a two ounce weight and a short hook link of about eight inches. My main line is 20lb breaking strain mono and so is the hook link. You can make it more complicated if you wish, but that set up has worked well for me for as many years as I can remember.',
                    'I sit on the bank if it’s dry, or relax in a lightweight chair, as I’m only going to be out for an hour or two. That’s enough for me nowadays. I could fish all through the night, but I can’t face the next day feeling like death-warmed-up anymore. I very often find that a result comes early, and I always have the option of staying longer if the fancy takes me.',
                    'As the sessions are so short, I often dispense with alarms and lay the rods on the ground and use a piece of light aluminium foil as a bite indicator. A huge part of the magic is when the foil goes scraping along the ground. In the still of the night you cannot help but hear it clattering up to the butt ring.',
                    'The eel does not have to be a big one to give me pleasure. A modest sized two pounders put up a good fight, and often lead me into thinking it’s much bigger. Really big eels, those over four pounds to me, come now and again, and I find them just as awesome to handle and inspect now as I ever did.',
                    'As they slither back into the water, I feel very satisfied that I’ve come face-to-face with one of natures most fascinating creatures. After a shower and a mug of hot tea, sleep comes easy, and I dream of doing it again!',
                ],
            },
        },
    },
];

export default predatorsPosts;
