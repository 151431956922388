import React from 'react';
// import {
//     EmailShareButton,
//     EmailIcon,
//     FacebookShareButton,
//     FacebookIcon,
//     TwitterShareButton,
//     TwitterIcon,
//     WhatsappShareButton,
//     WhatsappIcon,
// } from 'react-share';

const SocialShare = () => {
    return <></>;
    // const shareUrl = document.URL;
    // const title = document.title;
    // return (
    //     <div className="w-100 social__outer">
    //         <div className="inner-content">
    //             <h4>Share this article</h4>
    //             <div className="social__container">
    //                 <EmailShareButton
    //                     url={shareUrl}
    //                     quote={title}
    //                     className="Demo__some-network__share-button"
    //                 >
    //                     <EmailIcon size={32} round />
    //                 </EmailShareButton>
    //                 <FacebookShareButton
    //                     url={shareUrl}
    //                     quote={title}
    //                     className="Demo__some-network__share-button"
    //                 >
    //                     <FacebookIcon size={32} round />
    //                 </FacebookShareButton>
    //                 <TwitterShareButton
    //                     url={shareUrl}
    //                     quote={title}
    //                     className="Demo__some-network__share-button"
    //                 >
    //                     <TwitterIcon size={32} round />
    //                 </TwitterShareButton>
    //                 <WhatsappShareButton
    //                     url={shareUrl}
    //                     quote={title}
    //                     className="Demo__some-network__share-button"
    //                 >
    //                     <WhatsappIcon size={32} round />
    //                 </WhatsappShareButton>
    //             </div>
    //         </div>
    //     </div>
    // );
};

export default SocialShare;
