import React, { useEffect } from 'react';
import Modal from 'react-modal';

const SiteModal = ({ showModal, closeModal, children, customClass = '' }) => {
    const [modalIsOpen, setIsOpen] = React.useState(false);

    // Only do this once
    useEffect(() => {
        setIsOpen(showModal);
    }, [modalIsOpen, showModal]);

    Modal.setAppElement('#root');

    return (
        <Modal
            className={`Modal ${customClass}`}
            isOpen={showModal}
            onRequestClose={closeModal}
            overlayClassName="Overlay"
            aria={{
                labelledby: 'heading',
                describedby: 'titleDescription',
            }}
        >
            <button
                className="close-modal"
                onClick={closeModal}
                aria-label="Close modal"
                title="Close"
            >
                <div className="button-label">x</div>
            </button>
            {children}
        </Modal>
    );
};

export default SiteModal;
