import React from 'react';
import classNames from 'classnames';

const createMarkup = para => {
    return { __html: para };
};

// Puts the copy either into <p> tag or list depending on what's needed
const DisplayCopy = ({ copy }) => {
    return copy.map((item, idx) => {
        return (
            <React.Fragment key={idx}>
                <p key={idx} dangerouslySetInnerHTML={createMarkup(item)} />
            </React.Fragment>
        );
    });
};

const PageStrap = ({ copy, customClass, leftAlign = false }) => {
    const { title, blurb } = copy;
    const copyClasses = classNames('blurb', customClass);
    const alignClass = leftAlign ? 'left' : 'center';
    return (
        <div className="w-100 page-strap">
            <div className={`inner-content ${alignClass}`}>
                <h1>{title}</h1>
                <div className={copyClasses}>
                    <DisplayCopy copy={blurb} />
                </div>
            </div>
        </div>
    );
};

export default PageStrap;
