import React, { Suspense, lazy, useLayoutEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
// import ScrollToTop from 'react-router-scroll-top';
import Layout from '../layouts/';
import Helmet from 'react-helmet';
import meta from 'constants/seo';
import { companyInfo } from 'constants/misc';

function lazyWithPreload(componentImport) {
    const Component = React.lazy(componentImport);
    Component.preload = componentImport;
    return Component;
}

const Home = lazyWithPreload(() => import('../containers/Home'));
const About = lazyWithPreload(() => import('../containers/About'));
const Contact = lazyWithPreload(() => import('../containers/Contact'));
const Shop = lazyWithPreload(() => import('../containers/Shop'));
const Predators = lazyWithPreload(() => import('../containers/Predators'));
const PredatorTalk = lazyWithPreload(() =>
    import('../containers/PredatorTalk')
);
const Gallery = lazyWithPreload(() => import('../containers/Gallery'));

About.preload();
Contact.preload();
Gallery.preload();
Predators.preload();
PredatorTalk.preload();

const Pike = lazy(() => import('containers/Predators/Pike'));
const Eel = lazy(() => import('containers/Predators/Eel'));
const Zander = lazy(() => import('containers/Predators/Zander'));
const Catfish = lazy(() => import('containers/Predators/Catfish'));

const TheGoodOldMackerelTail = lazy(() =>
    import('containers/PredatorTalk/TheGoodOldMackerelTail')
);
const ScentTrails = lazy(() => import('containers/PredatorTalk/ScentTrails'));
const BraidOrMono = lazy(() => import('containers/PredatorTalk/BraidOrMono'));
const AllTogetherGirls = lazy(() =>
    import('containers/PredatorTalk/AllTogetherGirls')
);
const WaterTemperatureWarning = lazy(() =>
    import('containers/PredatorTalk/WaterTemperatureWarning')
);
const HowManyRods = lazy(() => import('containers/PredatorTalk/HowManyRods'));
const TwitchIt = lazy(() => import('containers/PredatorTalk/TwitchIt'));
const PlanningAhead = lazy(() =>
    import('containers/PredatorTalk/PlanningAhead')
);
const FlyRetrieves = lazy(() => import('containers/PredatorTalk/FlyRetrieves'));
const MovingHotSpots = lazy(() =>
    import('containers/PredatorTalk/MovingHotSpots')
);
const ShortEelSessions = lazy(() =>
    import('containers/PredatorTalk/ShortEelSessions')
);

const ScrollToTop = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
};

const RenderWithLayout = ({ Component, Layout, props = {}, page = '' }) => {
    const { siteUrl, docTitlePrefix } = companyInfo;
    const pageMeta = meta[page];

    const { docTitle, description, canonical } = pageMeta;
    const currentUrl = window.location.href;

    const pageTitle = `${docTitlePrefix}${docTitle}`;
    const pageUrl = `${siteUrl}${canonical}`;

    return (
        <Layout>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={pageUrl} />
                <meta
                    property="og:image"
                    content={`${currentUrl}img/heros/hp-hero.jpg`}
                />
                <meta property="og:description" content={description} />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:url" content={pageUrl} />
            </Helmet>
            <ScrollToTop>
                <Component {...props} />
            </ScrollToTop>
        </Layout>
    );
};

const AllRoutes = () => {
    return (
        <Suspense fallback={''}>
            <Routes>
                {/* <ScrollToTop> */}
                <Route
                    path="/"
                    element={
                        <RenderWithLayout
                            Component={Home}
                            Layout={Layout}
                            page="home"
                        />
                    }
                />
                <Route
                    path="/about"
                    element={
                        <RenderWithLayout
                            Component={About}
                            Layout={Layout}
                            page="about"
                        />
                    }
                />
                <Route
                    path="/contact"
                    element={
                        <RenderWithLayout
                            Component={Contact}
                            Layout={Layout}
                            page="contact"
                        />
                    }
                />
                <Route
                    path="/shop"
                    element={
                        <RenderWithLayout
                            Component={Shop}
                            Layout={Layout}
                            page="shop"
                        />
                    }
                />
                <Route
                    path="/predators"
                    element={
                        <RenderWithLayout
                            Component={Predators}
                            Layout={Layout}
                            page="predators"
                        />
                    }
                />
                <Route
                    path="/predator-talk"
                    element={
                        <RenderWithLayout
                            Component={PredatorTalk}
                            Layout={Layout}
                            page="predatorTalk"
                        />
                    }
                />
                <Route
                    path="/gallery"
                    element={
                        <RenderWithLayout
                            Component={Gallery}
                            Layout={Layout}
                            page="gallery"
                        />
                    }
                />
                <Route
                    path="/predators/pike"
                    element={
                        <RenderWithLayout
                            Component={Pike}
                            Layout={Layout}
                            page="predatorsPike"
                        />
                    }
                />
                <Route
                    path="/predators/eel"
                    element={
                        <RenderWithLayout
                            Component={Eel}
                            Layout={Layout}
                            page="predatorsEel"
                        />
                    }
                />
                <Route
                    path="/predators/zander"
                    element={
                        <RenderWithLayout
                            Component={Zander}
                            Layout={Layout}
                            page="predatorsZander"
                        />
                    }
                />
                <Route
                    path="/predators/catfish"
                    element={
                        <RenderWithLayout
                            Component={Catfish}
                            Layout={Layout}
                            page="predatorsCatfish"
                        />
                    }
                />
                <Route
                    path="/predator-talk/the-good-old-mackerel-tail"
                    element={
                        <RenderWithLayout
                            Component={TheGoodOldMackerelTail}
                            Layout={Layout}
                            page="theGoodOldMackerelTail"
                        />
                    }
                />
                <Route
                    path="/predator-talk/scent-trails"
                    element={
                        <RenderWithLayout
                            Component={ScentTrails}
                            Layout={Layout}
                            page="scentTrails"
                        />
                    }
                />
                <Route
                    path="/predator-talk/braid-or-mono-for-pike"
                    element={
                        <RenderWithLayout
                            Component={BraidOrMono}
                            Layout={Layout}
                            page="braidOrMono"
                        />
                    }
                />
                <Route
                    path="/predator-talk/all-together-girls"
                    element={
                        <RenderWithLayout
                            Component={AllTogetherGirls}
                            Layout={Layout}
                            page="allTogetherGirls"
                        />
                    }
                />
                <Route
                    path="/predator-talk/water-temperature-warning"
                    element={
                        <RenderWithLayout
                            Component={WaterTemperatureWarning}
                            Layout={Layout}
                            page="waterTemp"
                        />
                    }
                />
                <Route
                    path="/predator-talk/how-many-rods"
                    element={
                        <RenderWithLayout
                            Component={HowManyRods}
                            Layout={Layout}
                            page="howManyRods"
                        />
                    }
                />
                <Route
                    path="/predator-talk/twitch-it"
                    element={
                        <RenderWithLayout
                            Component={TwitchIt}
                            Layout={Layout}
                            page="twitchIt"
                        />
                    }
                />
                <Route
                    path="/predator-talk/planning-ahead-big-pike"
                    element={
                        <RenderWithLayout
                            Component={PlanningAhead}
                            Layout={Layout}
                            page="planningAhead"
                        />
                    }
                />
                <Route
                    path="/predator-talk/pike-fly-fishing-retrieves"
                    element={
                        <RenderWithLayout
                            Component={FlyRetrieves}
                            Layout={Layout}
                            page="flyRetrieves"
                        />
                    }
                />
                <Route
                    path="/predator-talk/moving-hot-spots"
                    element={
                        <RenderWithLayout
                            Component={MovingHotSpots}
                            Layout={Layout}
                            page="movingHotSpots"
                        />
                    }
                />
                <Route
                    path="/predator-talk/short-eel-sessions"
                    element={
                        <RenderWithLayout
                            Component={ShortEelSessions}
                            Layout={Layout}
                            page="shortEelSessions"
                        />
                    }
                />
                {/* </ScrollToTop> */}
            </Routes>
        </Suspense>
    );
};

export default AllRoutes;
