const galleryImages = [
    {
        path: 'img/gallery/gallery01.webp',
    },
    {
        path: 'img/gallery/gallery02.webp',
    },
    {
        path: 'img/gallery/gallery03.webp',
    },
    {
        path: 'img/gallery/gallery04.webp',
    },
    {
        path: 'img/gallery/gallery05.webp',
    },
    {
        path: 'img/gallery/gallery06.webp',
    },
    {
        path: 'img/gallery/gallery07.webp',
    },
    {
        path: 'img/gallery/gallery08.webp',
    },
    {
        path: 'img/gallery/gallery09.webp',
    },
    {
        path: 'img/gallery/gallery10.webp',
    },
    {
        path: 'img/gallery/gallery11.webp',
    },
    {
        path: 'img/predator-fish/pike-article.webp',
    },
    {
        path: 'img/predator-fish/eel-article.webp',
    },
    {
        path: 'img/predator-fish/zander-article.webp',
    },
    {
        path: 'img/predator-fish/pike1.webp',
    },
    {
        path: 'img/predator-fish/eel1.webp',
    },
    {
        path: 'img/predator-fish/zander2.webp',
    },
    {
        path: 'img/predator-fish/catfish2.webp',
    },
    {
        path: 'img/page-images/predator-talk/alltogether1.jpg',
    },
    {
        path: 'img/page-images/predator-talk/alltogether2.jpg',
    },
    {
        path: 'img/page-images/predator-talk/braid1.jpg',
    },
    {
        path: 'img/page-images/predator-talk/braid2.jpg',
    },
    {
        path: 'img/page-images/predator-talk/flyretrieves1.jpg',
    },
    {
        path: 'img/page-images/predator-talk/flyretrieves2.jpg',
    },
    {
        path: 'img/page-images/predator-talk/flyretrieves3.jpg',
    },
    {
        path: 'img/page-images/predator-talk/howmanyrods1.jpg',
    },
    {
        path: 'img/page-images/predator-talk/howmanyrods2.jpg',
    },
    {
        path: 'img/page-images/predator-talk/mack1.jpg',
    },
    {
        path: 'img/page-images/predator-talk/mack2.jpg',
    },
    {
        path: 'img/page-images/predator-talk/mack3.jpg',
    },
    {
        path: 'img/page-images/predator-talk/movinghotspots1.jpg',
    },
    {
        path: 'img/page-images/predator-talk/movinghotspots2.jpg',
    },
    {
        path: 'img/page-images/predator-talk/planningahead1.jpg',
    },
    {
        path: 'img/page-images/predator-talk/planningahead2.jpg',
    },
    {
        path: 'img/page-images/predator-talk/scent1.jpg',
    },
    {
        path: 'img/page-images/predator-talk/scent2.jpg',
    },
    {
        path: 'img/page-images/predator-talk/twitchit1.jpg',
    },
    {
        path: 'img/page-images/predator-talk/twitchit2.jpg',
    },
    {
        path: 'img/page-images/predator-talk/watertemp1.jpg',
    },
    {
        path: 'img/page-images/predator-talk/watertemp2.jpg',
    },
    {
        path: 'img/page-images/predator-talk/watertemp3.jpg',
    },
    {
        path: 'img/gallery/gallery12.jpg',
    },
    {
        path: 'img/gallery/gallery13.jpg',
    },
    {
        path: 'img/gallery/gallery14.jpg',
    },
    {
        path: 'img/gallery/gallery15.jpg',
    },
    {
        path: 'img/gallery/gallery16.jpg',
    },
    {
        path: 'img/gallery/gallery17.jpg',
    },
    {
        path: 'img/gallery/gallery18.jpg',
    },
    {
        path: 'img/gallery/gallery19.jpg',
    },
    {
        path: 'img/gallery/gallery20.jpg',
    },
    {
        path: 'img/gallery/gallery21.jpg',
    },
    {
        path: 'img/gallery/gallery22.jpg',
    },
];

export default galleryImages;
