import React from 'react';
import { ReadMoreLink } from 'components';

const StoryImage = ({ imgUrl, client }) => (
    <div className="double-block__story__image">
        <img src={imgUrl} className="img-fluid" alt={client} />
    </div>
);

const StoryCopy = ({ title, client, blurb, linkUrl }) => (
    <div className="double-block__story__copy">
        <div className="double-block__story__copy-container">
            <div className="double-block__story__copy-container__title">
                {title}
            </div>
            {client && (
                <div className="double-block__story__copy-container__client">
                    {client}
                </div>
            )}
            <div className="double-block__story__copy-container__blurb">
                {blurb}
            </div>
            {linkUrl.startsWith('http') ? (
                <a
                    href={linkUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="read-more-link light"
                >
                    Read more
                </a>
            ) : (
                <ReadMoreLink url={linkUrl} mode="light" />
            )}
        </div>
    </div>
);

const StoryBlock = ({ copy, imgUrl, imgPosition }) => {
    const screenWidth = window.innerWidth;
    const content =
        imgPosition === 'right' && screenWidth >= 768 ? (
            <>
                <StoryCopy {...copy} />
                <StoryImage imgUrl={imgUrl} client={copy.client} />
            </>
        ) : (
            <>
                <StoryImage imgUrl={imgUrl} client={copy.client} />
                <StoryCopy {...copy} />
            </>
        );
    return (
        <div className={`double-block__story ${imgPosition}`}>{content}</div>
    );
};

export default StoryBlock;
