import React from 'react';
import classNames from 'classnames';
import predators, { sectionTitle } from './predatorsConstants';
import { Link } from 'react-router-dom';

const PredatorsDisplay = ({ predators }) =>
    predators
        .filter(item => item.active)
        .map((item, idx) => {
            const imageClasses = classNames(
                'predators__service-item__image-container__image img-fluid',
                item.customClass
            );
            return (
                <div className='predators__service-item' key={idx}>
                    <Link
                        className="predators__service-item__image-container"
                        to={item.link}
                    >
                        <div className="read-more-predators">{`${item.title}`}</div>
                        <img
                            className={imageClasses}
                            src={item.imageUrl}
                            alt={item.title}
                            title={item.title}
                        />

                    </Link>
                </div>
            );
        });

const Predators = ({ showTitle = false }) => {
    return (
        <div className="border-bottom predators">
            <div className="inner-content">
                {showTitle && <h2>{sectionTitle}</h2>}
                <div className="predators__container">
                    <PredatorsDisplay predators={predators} />
                </div>
            </div>
        </div>
    );
};

export default Predators;
