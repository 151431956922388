import React from 'react';
import YouTube from 'react-youtube';
import { LoadingSpinner } from 'components';

const youtubeIds = [
    'Rs88nbXctiI',
    'MkSXoo1s7HM',
    '7prRDS-BsoA',
    'JagFAqWc7pw',
    '_jcqFkmez1U',
    'q1E6tVGYq4U',
    'p2Wy0cs0uPg',
    'uVMq_wEWSzA',
];

const videos = youtubeIds.map(video => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 0,
        },
    };
    return (
        <div className="video-container" key={video}>
            <div className="loader">
                <LoadingSpinner />
            </div>
            <YouTube videoId={video} opts={opts} />
        </div>
    );
});

const GalleryVideos = () => {
    return <div className="gallery-videos">{videos}</div>;
};

export default GalleryVideos;
