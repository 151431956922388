import React from 'react';
import { useLocation } from 'react-router-dom';

export const GetCurrentPage = () => {
    let location = useLocation();

    return location.pathname;
};

// // Show/hide the loading spinner - options are "show" or "hide"
// export const loadingSpinner = state =>
//     (document.getElementById('pageLoadingCoverAnimation').classList = state);

// export const checkEmail = emailAddress => {
//     const sQtext = '[^\\x0d\\x22\\x5c\\x80-\\xff]';
//     const sDtext = '[^\\x0d\\x5b-\\x5d\\x80-\\xff]';
//     const sAtom =
//         '[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+';
//     const sQuotedPair = '\\x5c[\\x00-\\x7f]';
//     const sDomainLiteral = '\\x5b(' + sDtext + '|' + sQuotedPair + ')*\\x5d';
//     const sQuotedString = '\\x22(' + sQtext + '|' + sQuotedPair + ')*\\x22';
//     const sDomain_ref = sAtom;
//     const sSubDomain = '(' + sDomain_ref + '|' + sDomainLiteral + ')';
//     const sWord = '(' + sAtom + '|' + sQuotedString + ')';
//     const sDomain = sSubDomain + '(\\x2e' + sSubDomain + ')*';
//     const sLocalPart = sWord + '(\\x2e' + sWord + ')*';
//     const sAddrSpec = sLocalPart + '\\x40' + sDomain; // complete RFC822 email address spec
//     const sValidEmail = '^' + sAddrSpec + '$'; // as whole string

//     const reValidEmail = new RegExp(sValidEmail);

//     return reValidEmail.test(emailAddress);
// };

export const capitalizeFirstLetter = string =>
    string.charAt(0).toUpperCase() + string.slice(1);

export const ArticleContent = ({ content }) => {
    console.log('ArticleContent -> content', content);
    return content.blurb.paras.map((para, idx) => {
        const imageForThisParagraph = content.blurb.images.find(
            img => img.para === idx
        );
        return (
            <p key={idx}>
                {imageForThisParagraph && (
                    <img
                        src={imageForThisParagraph.src}
                        className={imageForThisParagraph.imgClass}
                        alt="Mick"
                    />
                )}
                {para}
            </p>
        );
    });
};

export const MainImage = ({ content }) => {
    const mainImage = content.blurb.images.find(img => img.imgClass === 'main');
    return mainImage ? (
        <p>
            <img
                src={mainImage.src}
                className={mainImage.imgClass}
                alt="Mick"
            />
        </p>
    ) : (
        ''
    );
};
