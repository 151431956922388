const predatorsPosts = [
    {
        id: 'pike',
        url: '/predators/pike',
        pageStrap: {
            title: 'Pike',
            blurb: [],
        },

        articleCopy: {
            blurb: {
                images: [
                    {
                        para: 0,
                        src: '/img/predator-fish/pike-article.webp',
                        imgClass: 'left',
                    },
                    {
                        para: 4,
                        src: '/img/predator-fish/pike1.webp',
                        imgClass: 'right',
                    },
                ],
                paras: [
                    'The pike is Mick’s favourite species, and he has travelled far and wide to catch them. They are very widespread in the UK and can be found in rivers, drains, canals, natural lakes, gravel pits and reservoirs. The tactics for catching them vary from venue to venue, but their habits are fairly constant.',
                    'Although they feed throughout the year, they do not fare well when caught in the warmer summer months, and nowadays the caring angler tends to fish for them when it is safe to do so. As a general guide, Mick prefers to fish for them when the water temperature falls below 10 deg C. At slightly higher temperatures, up to 15 deg C, extra attention will be needed to prevent them from rapidly swallowing baits and struggling to recover after capture. At water temperatures above 15 deg C, there is a high risk of mortalities and in turn ruining the pike fishing in the venue. At any time of year, it is important that the angler has the correct equipment for handling them safely and understands how to do so.',
                    'They can be caught using bait and lure methods. Bait fishing was once the main approach for pike in the UK, but there has been a gradual move in recent decades towards using artificial baits. These will include spinners and spoons, spinnerbaits, plugs that work at all depths, surface lures, jerk baits, jigs and flies.',
                    'Deadbaiting can be very effective in most places, but there will be times when the bait angler would fare much better with a livebait. Popular deadbaits include Mick’s favourites which are lamprey, smelt, sardine, herring and mackerel. With live and deadbaiting, there are many useful rigs and methods.',
                    'Mick favours a multi method approach unless a venue favours one in particular. Most sessions he would endeavour to have livebaits, deadbaits and a variety of lures at his disposal.',
                    'The keen pike angler will have to consider the time of day when pike might feed, and the weather conditions that trigger them to do so. This can make pike fishing very challenging to achieve regular success. Patterns do emerge for those that put in the effort and experiment.',
                    'The pike is an extremely efficient hunter that has been around for millions of years, weeding out the weaker prey fish (and other water creatures) and clearing up the dead. It is capable of such ruthless efficiency due to its keen eyesight, highly developed sense of smell, and an inbuilt mechanism to help it track prey through vibration. It is an important underwater predator in the UK, and healthy stocks of other species rely upon it carrying out its purpose.',
                ],
            },
        },
    },
    {
        id: 'eel',
        url: '/predators/eel',
        pageStrap: {
            title: 'Eel',
            blurb: [],
        },

        articleCopy: {
            blurb: {
                images: [
                    {
                        para: 0,
                        src: '/img/predator-fish/eel-article.webp',
                        imgClass: 'left',
                    },
                    {
                        para: 4,
                        src: '/img/predator-fish/eel1.webp',
                        imgClass: 'right',
                    },
                ],
                paras: [
                    'Anglers tend to be very interested in the incredible life cycle of the eel and the anecdotes regarding its behaviour, yet very few fish for them by design. That’s probably not a bad thing right now as their numbers have plummeted in recent decades, partly through environmental reasons, but also through overfishing by nets at both the elver and adult stage.',
                    'Their amazing life cycle can be summed up in a simple way. They spawn in the Sargasso Sea, and then make an incredible journey across the Atlantic Ocean. They populate our rivers and lakes, and then return to repeat the cycle. That’s probably enough information for us anglers, but the facts go far deeper and are enshrouded with mystery and lack of detail. If you would care to check out Wikipedia – Eel Life History - and delve into some of the associated papers, you will find a wealth of absorbing reading that will leave you in no doubt that this is quite an incredible creature.',
                    'They used to be commercially harvested for food in great numbers in the UK river estuaries when they arrived from the sea and were only a few inches long. Then the adult eels were again harvested when the started their return journey to spawn. In between these two periods in their life cycle, is when us anglers’ fish for them. Although anglers have contributed a negligible amount to the eel’s decline in numbers, we have restrictions imposed upon us as to how we fish for them. Potential eel anglers would be advised to check out the Environment Agency latest laws regarding fishing for eels.',
                    'Fishing for them is quite a simple affair, and there is no need for complex tackle or bait. The tackle needs to be strong enough to haul them from snags, which they will make every effort to get their tails around, and the bait choice for me is simply worm or a dead coarse fish. Livebaiting is a fantastic method if you can overcome the problem of keeping a bait alive in warm summer water temperatures. Legering is a most popular method, and preferred to freelining, which is a method that slows down bite detection and leads to deep hooking.',
                    'Serious eel anglers understand how eels don’t always feed on the bottom, as is widely believed, and have developed mid water and surface rigs. Others are experimenting with more unusual baits.',
                    'Fishing is mostly carried out at night, but sport can often be found in the daytime in water that is naturally coloured, or coloured by floodwater. The eel has a very acute sense of smell and anglers like to puncture their deadbaits or cut off the ends of their worms to increase attraction.',
                    'Eel anglers often tend to become obsessed and classed as eccentrics. Maybe that’s the result of endless nights without sleep and wandering around in the dark, covered in mud and slime, and feeling like ‘death warmed up’ in the morning. Not a pursuit recommended for the family man or one with a weak stomach!',
                ],
            },
        },
    },
    {
        id: 'zander',
        url: '/predators/zander',
        pageStrap: {
            title: 'Zander',
            blurb: [],
        },

        articleCopy: {
            blurb: {
                images: [
                    {
                        para: 0,
                        src: '/img/predator-fish/zander-article.webp',
                        imgClass: 'right',
                    },
                    {
                        para: 3,
                        src: '/img/predator-fish/zander2.webp',
                        imgClass: 'left',
                    },
                ],
                paras: [
                    'The zander is not a native species to the UK, and was only successfully introduced in the last sixty years or so after several failed attempts. An experimental stocking in the Cambridgeshire fens got out of hand, and the species gradually spread and populated many river systems and still waters, mostly in the Midlands area. Initially bringing controversy regarding their potential risk to the smaller prey species, things eventually settled down and zander fit in perfectly in most venues and provide very interesting sport to predator anglers.',
                    'Early attempts to catch them were focused on bait fishing, and it wasn’t for thirty years or so that UK anglers started to catch them on lures, just as continental anglers had been doing and telling us about for a very long time. In fact, in the right hands, lure fishing tactics will easily out fish a bait fishing approach. In particular, soft plastic jigs have been most effective with their ability to fish efficiently close to the bottom where zander often feed. In a similar vein, trolling close to the bottom has been found to be a very effective way of catching them.',
                    'As with pike, the angler can choose between livebaiting, deadbaiting and the many lure methods. Zander anglers seem to fall into one camp or the other though. Mick’s preference, as when pike fishing, is to have bait and lures at his disposal but concedes that there are times when lures will be far superior, especially when a lot of water needs to be covered.',
                    'Zander feed better in low light conditions, and night is often the best time. However, when the water is coloured, either naturally or through flood water, zander can be caught at any time.',
                    'Locating zander is the anglers biggest challenge, and once located they can be very easy to catch with the right approach. The holding areas can be very tight and it’s not unusual to find great distances between zander packs. If not over fished, such zander holding spots can be reliable time after time.',
                    'At one time, bait anglers thought that zander would not take sea deadbaits. This is being disproven time and time again, and in particular smelt has been very effective. Of the fresh water dead baits, lamprey, eel section and any silver fish work well.',
                    'Like the pike, the zander is a hunting machine using sight, smell and vibration detection to find its prey. Its eyesight is very much adapted by nature for low light feeding.',
                    'Mick’s view on when to fish for zander is very similar to that for pike. Water temperature is once again the key, and although zander will tolerate being caught in slightly higher water temperatures than pike, the angler should be extremely considerate with regard to preventing deep hooking and excessive handling.',
                ],
            },
        },
    },
    {
        id: 'catfish',
        url: '/predators/catfish',
        pageStrap: {
            title: 'Catfish',
            blurb: [],
        },

        articleCopy: {
            blurb: {
                images: [
                    {
                        para: 0,
                        src: '/img/predator-fish/catfish-article.webp',
                        imgClass: 'left',
                    },
                    {
                        para: 3,
                        src: '/img/predator-fish/catfish2.webp',
                        imgClass: 'right',
                    },
                ],
                paras: [
                    'Catfish were first introduced into the UK over 100 years ago, but they never grew particularly big, and interest in them was minimal. Then, in the nineties, they were imported in large numbers, some officially, some unofficially, until we arrive at the situation we have today where they are in at least 500 waters. This makes catfishing now accessible to so many anglers, and there is great demand to fish waters with them. What’s more, the new strains of fish are real growers, and thriving in our waters. A few years ago, a forty pounder was our target, before long we were catching ‘sixties’, and now hundred pounders are not at all unusual. In most waters, they don’t seem to have reached their potential, so who knows where it will all end?',
                    'Catfishing is now highly developed on the continent too, and British anglers are joining in the chase for monsters in excess of two hundred pounds. Not surprisingly, tackle and bait have also developed. The tackle might seem very heavy and ‘over the top’, but when you hook into an unstoppable force in the middle of the night, you will realise why. The fights are simply brutal, and test an angler’s strength, as well as his skill.',
                    'To some extent it is unfortunate that many of the biggest UK cats live in quite small pools. This makes the landing of them fairly predictable as you can’t run out of line. By contrast, hooking a big cat on a larger water is a real test of skill as it’s possible to run out of line before you stop them running. Tackling up accordingly becomes essential.',
                    'Baits and methods are endless. Mick favours a small livebait popped up from the bottom. The catfish has a highly developed system that helps it to amplify and detect movement. He has also had great success with eel sections on a kebab style rig, and more common baits like large halibut pellets and big cubes of luncheon meat.',
                    'On some waters they mainly feed after dark, but where bait is thrown in in great quantities by carp anglers, expect a run from a catfish at any time. Although the catfish is a real scavenger, mopping up all the bait in sight, it is also a very skilled attack fish, and its large whiskers act as a kind of radar to pinpoint any live prey.',
                    'Handling them increases in difficulty with their size.Mauling these beasts about can be back breaking and requires a well prepared plan to unhook, weigh and photograph them. If not you will have the fish on the bank for too long and cause it distress. Fishing with a mate is a definite help. Pioneering catfish anglers made their own gear such as large nets and weigh slings. Nowadays you can buy it off the shelf.',
                    'Above all, catfishing is great fun. They are ridiculously strong fish and will put up a fight to be remembered. Just get the right gear, hook into one, hold on, and enjoy it!',
                ],
            },
        },
    },
];

export default predatorsPosts;
