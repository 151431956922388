import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const GalleryTabs = ({ images, videos }) => {
    return (
        <Tabs>
            <TabList>
                <Tab>Image Gallery</Tab>
                <Tab>YouTube Videos</Tab>
            </TabList>

            <TabPanel>{images}</TabPanel>
            <TabPanel>{videos}</TabPanel>
        </Tabs>
    );
};

export default GalleryTabs;
