import React, { useState } from 'react';
import HeaderCopy from 'constants/modules/Header.js';
import { Link } from 'react-router-dom';
import { GetCurrentPage } from 'utils/commonUtils';
import classNames from 'classnames';

const { headerLinks } = HeaderCopy;

const Links = ({ setMenuOpen }) => {
    const currentPage = GetCurrentPage();
    return headerLinks
        .filter(link => link.active)
        .filter(link => link.sideNav)
        .map(link => {
            const isActive = currentPage === link.linkTo;
            return (
                <li key={link.linkTo}>
                    <Link
                        to={link.linkTo}
                        className="my-auto"
                        aria-current={isActive}
                        onClick={() => setMenuOpen(false)}
                    >
                        {link.label}
                    </Link>
                    {link.children && (
                        <ul className="sub-links">
                            {link.children.map((subLink, idx) => {
                                const isActive = currentPage === subLink.linkTo;
                                return (
                                    <li key={idx}>
                                        <Link
                                            to={subLink.linkTo}
                                            className="my-auto"
                                            aria-current={isActive}
                                        >
                                            {subLink.label}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </li>
            );
        });
};

const SideBarMenu = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const containerClasses = classNames('sidebar-menu', menuOpen && 'open');
    const burgerClasses = classNames(
        'btn',
        'close-menu-icon',
        menuOpen && 'open'
    );
    return (
        <>
            <button
                id="burger-menu"
                className={burgerClasses}
                onClick={() => setMenuOpen(!menuOpen)}
            >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </button>
            <div className={containerClasses}>
                <Link to="/">
                    <img
                        src="/img/brand/logo_color.png"
                        className="logo"
                        alt="Company logo"
                    />
                </Link>
                <ul className="header-side-nav">
                    <Links setMenuOpen={setMenuOpen} />
                </ul>
            </div>
        </>
    );
};

export default SideBarMenu;
