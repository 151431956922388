import React from 'react';
import { ReadMoreLink } from 'components';
import predatorTalkPosts from 'constants/containers/predatorTalkPosts';

const LatestPredatorPost = ({ url, pageStrap, author }) => (
    <div className="homepage-hero__copy__container">
        <div className="homepage-hero__copy__container--left">
            <h3>{pageStrap.title}</h3>
            <div className="sub-title">{author}</div>
        </div>
        <div className="homepage-hero__copy__container--right">
            <ReadMoreLink url={url} />
        </div>
    </div>
);

const HomePageHero = () => {
    const latestPredatorTalkPost =
        predatorTalkPosts[predatorTalkPosts.length - 1];
    const { url, pageStrap, author } = latestPredatorTalkPost;
    return (
        <div className="homepage-hero-wrapper">
            <div className="homepage-hero">
                <div className="homepage-hero__copy">
                    <div className="inner-content">
                        <LatestPredatorPost
                            url={url}
                            pageStrap={pageStrap}
                            author={author}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePageHero;
