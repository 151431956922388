const meta = {
    home: {
        docTitle: 'The Duke',
        canonical: '',
        description: "Mick's world of pike and predator fishing",
    },
    predatorsPike: {
        docTitle: 'Predators - Pike',
        canonical: 'predators/pike',
        description: 'Pike angling',
    },
    predatorsEel: {
        docTitle: 'Predators - Pike',
        canonical: 'predators/eel',
        description: 'Pike angling',
    },
    predatorsZander: {
        docTitle: 'Predators - Pike',
        canonical: 'predators/zander',
        description: 'Pike angling',
    },
    predatorsCatfish: {
        docTitle: 'Predators - Pike',
        canonical: 'predators/catfish',
        description: 'Pike angling',
    },
    predators: {
        docTitle: 'Predators',
        canonical: 'predators',
        description: 'Learn about the angling predators!',
    },
    predatorTalk: {
        docTitle: 'Predator Talk',
        canonical: 'predator-talk',
        description:
            "Learn tips and tricks from Mick's many years of pike and predator fishing",
    },
    gallery: {
        docTitle: 'Predators - Pike',
        canonical: 'gallery',
        description:
            "Images and videos of Mick's pike and predator fishing adventures",
    },
    about: {
        docTitle: 'Predators - Pike',
        canonical: 'about',
        description: 'About Mick - The Duke',
    },
    shop: {
        docTitle: 'Predators - Pike',
        canonical: 'shop',
        description: 'Shop Mick',
    },
    contact: {
        docTitle: 'Predators - Pike',
        canonical: 'contact',
        description: 'How to contact Mick Brown',
    },
    theGoodOldMackerelTail: {
        docTitle: 'Predator talk - The good old mackerel tail',
        canonical: 'predator-talk/the-good-old-mackerel-tail',
        description: 'Using mackerel tail as bait for predator fishing',
    },
    scentTrails: {
        docTitle: 'Predator talk - Scent trails',
        canonical: 'predator-talk/scent-trails',
        description: 'Scent trails',
    },
    braidOrMono: {
        docTitle: 'Predator talk - Braid or mono for pike?',
        canonical: 'predator-talk/braid-or-mono-for-pike',
        description: 'Braid or mono for pike?',
    },
    allTogetherGirls: {
        docTitle: 'Predator talk - All together girls',
        canonical: 'predator-talk/all-together-girls',
        description: 'All together girls',
    },
    waterTemp: {
        docTitle: 'Predator talk - Water temperature warning',
        canonical: 'predator-talk/water-temperature-warning',
        description: 'Water temperature warning',
    },
    howManyRods: {
        docTitle: 'Predator talk - How many rods',
        canonical: 'predator-talk/how-many-rods',
        description: 'How many rods',
    },
    twitchIt: {
        docTitle: 'Predator talk - Twitch it!',
        canonical: 'predator-talk/twitch-it',
        description: 'Twitch it!',
    },
    planningAhead: {
        docTitle: 'Predator talk - Planning ahead for big pike',
        canonical: 'predator-talk/planning-ahead-big-pike',
        description: 'Planning ahead for big pike',
    },
    flyRetrieves: {
        docTitle: 'Predator talk - Pike fly fishing retrieves',
        canonical: 'predator-talk/pike-fly-fishing-retrieves',
        description: 'Pike fly fishing retrieves',
    },
    movingHotSpots: {
        docTitle: 'Predator talk - Moving hot spots',
        canonical: 'predator-talk/moving-hot-spots',
        description: 'Moving hot spots',
    },
    shortEelSessions: {
        docTitle: 'Predator talk - Short eel sessions',
        canonical: 'predator-talk/short-eel-sessions',
        description: 'Short eel sessions',
    },
};

export default meta;
