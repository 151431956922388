const predators = [
    {
        title: 'Pike',
        imageUrl: '/img/predator-fish/pike3.jpg',
        link: '/predators/pike',
        active: true,
    },
    {
        title: 'Perch',
        imageUrl: '/img/predator-fish/perch1.webp',
        link: '/',
        active: false,
    },
    {
        title: 'Eel',
        imageUrl: '/img/predator-fish/eel2.jpg',
        link: '/predators/eel',
        active: true,
    },
    {
        title: 'Zander',
        imageUrl: '/img/predator-fish/zander2.webp',
        link: '/predators/zander',
        active: true,
    },
    {
        title: 'Catfish',
        imageUrl: '/img/predator-fish/catfish3.jpg',
        link: '/predators/catfish',
        active: true,
    },
];

export const sectionTitle = 'Predators';

export default predators;
